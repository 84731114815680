import React, { useState } from "react";
import Scanner from "../../assets/image/Paytm.jpeg";
import { storage, firebase } from "../../db/Firebase";

const Registration = () => {
  const [formData, setFormData] = useState({
    studentName: "",
    fatherName: "",
    motherName: "",
    dob: "",
    aadharNumber: "",
    class: "",
    gender: "",
    mobileNumber: "",
    email: "",
    address: "",
    picture: null,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isScannerModalOpen, setIsScannerModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formKey, setFormKey] = useState(Date.now());
  const resetForm = () => {
    setFormData({
      studentName: "",
      fatherName: "",
      motherName: "",
      dob: "",
      aadharNumber: "",
      class: "",
      gender: "",
      mobileNumber: "",
      email: "",
      address: "",
      picture: null,
    });
    setFormKey(Date.now());
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, picture: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const formDataRef = firebase.database().ref("Registrations");
      const newFormDataRef = formDataRef.push();
      await newFormDataRef.set({
        ...formData,
        picture: null,
      });

      if (formData.picture) {
        const fileRef = storage.ref(
          `registrations/${newFormDataRef.key}/${formData.picture.name}`
        );
        await fileRef.put(formData.picture);
        const pictureURL = await fileRef.getDownloadURL();

        await newFormDataRef.update({
          picture: pictureURL,
        });
      }

      alert("Registration successful!");
      resetForm();
    } catch (error) {
      console.error("Error submitting form: ", error);
      alert("Error submitting registration. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleScannerModal = () => {
    setIsScannerModalOpen(!isScannerModalOpen);
  };

  return (
    <div className="mt-20 mb-20 font-poppins">
      <div className=" w-full h-32 bg-[#0c4680]"></div>
      <div className=" mx-5  md:mx-20">
        <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold mt-10">
          Home &gt; ADMISSIONS &gt; Registration
        </div>

        <div className="container mx-auto p-4 md:w-9/12 shadow-md mt-2">
          <h1 className="text-xl md:text-5xl font-bold text-[#0c4680] py-5 mb-5 text-center">
            Regitration Form
          </h1>
          <form
            onSubmit={handleSubmit}
            key={formKey}
            className="md:grid md:grid-cols-2 gap-4"
          >
            <label className="input-field">
              Student's Name
              <input
                type="text"
                name="studentName"
                placeholder="Student's Name"
                onChange={handleChange}
                className="border p-2 w-full"
                required
              />
            </label>
            <label className="input-field">
              Father's Name
              <input
                type="text"
                name="fatherName"
                placeholder="Father's Name"
                onChange={handleChange}
                className="border p-2 w-full"
                required
              />
            </label>
            <label className="input-field">
              Mother's Name
              <input
                type="text"
                name="motherName"
                placeholder="Mother's Name"
                onChange={handleChange}
                className="border p-2 w-full"
                required
              />
            </label>
            <label className="input-field">
              Date of Birth
              <input
                type="date"
                name="dob"
                onChange={handleChange}
                className="border p-2 w-full"
                required
              />
            </label>
            <label className="input-field">
              Aadhar Number
              <input
                type="text"
                name="aadharNumber"
                placeholder="Aadhar Number"
                onChange={handleChange}
                className="border p-2 w-full"
                required
              />
            </label>
            <label className="input-field">
              Class
              <select
                name="class"
                onChange={handleChange}
                className="border p-2 w-full"
                required
              >
                <option value="">Select Class</option>
                <option value="Nursery">Nursery</option>
                <option value="Nursery">LKG</option>
                <option value="Nursery">UKG</option>
                {Array.from({ length: 12 }, (_, i) => (
                  <option key={i + 1} value={`Class ${i + 1}`}>
                    Class {i + 1}
                  </option>
                ))}
              </select>
            </label>
            <label className="input-field">
              Gender
              <select
                name="gender"
                onChange={handleChange}
                className="border p-2 w-full"
                required
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </label>
            <label className="input-field">
              Mobile Number
              <input
                type="tel"
                name="mobileNumber"
                placeholder="Mobile Number"
                onChange={handleChange}
                className="border p-2 w-full"
                required
              />
            </label>
            <label className="input-field">
              Email
              <input
                type="email"
                name="email"
                placeholder="Email"
                onChange={handleChange}
                className="border p-2 w-full"
                required
              />
            </label>
            <div className="input-field">
              <button
                type="button"
                className="btn border p-2 w-full mt-6"
                onClick={toggleScannerModal}
              >
                Pay Fee
              </button>
            </div>

            {isScannerModalOpen && (
              <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
                <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                  <div className="mt-3 text-center">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Scan Code
                    </h3>
                    <div className="mt-2 px-7 py-3">
                      <img src={Scanner} alt="Scanner" className="mx-auto" />
                      <p className="text-sm text-gray-500">
                        Scan the QR code with Paytm to pay
                      </p>
                    </div>
                    <div className="items-center px-4 py-3">
                      <button
                        id="ok-btn"
                        className="px-4 py-2 bg-gray-800 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300"
                        onClick={toggleScannerModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <label className="input-field col-span-2">
              Address
              <textarea
                name="address"
                placeholder="Address"
                onChange={handleChange}
                className="border p-2 w-full"
                required
              ></textarea>
            </label>
            <div className="col-span-2">
              <label className="input-field">
                Upload Payment Reciept
                <input
                  type="file"
                  name="picture"
                  onChange={handleFileChange}
                  className="border p-2 w-full"
                />
              </label>
            </div>

            <button
              type="submit"
              className="bg-blue-900 md:w-32 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center justify-center disabled:bg-blue-300"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Loading...
                </>
              ) : (
                "Submit"
              )}
            </button>
          </form>

          {/* Modal */}
          {isModalOpen && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={toggleModal}>
                  &times;
                </span>
                <img src="/sample-image.jpg" alt="Sample" />
              </div>
            </div>
          )}
        </div>
        <div className="pt-10  md:pl-48">
         <ul className=" list-disc ">
          <li >After Successful payment of Fee Kindly give a missed call on This no. 9415101693</li>
          <li>Kindly share Payement Slip on This no.9415101693 via Whatsapp</li>
         </ul>
        </div>
      </div>
    </div>
  );
};

export default Registration;
