import React, { useEffect, useState } from 'react';
import { firebase } from '../db/Firebase';
import localImage from "../assets/image/annual.jpg";

const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([{ url: localImage, loaded: true }]);

  useEffect(() => {
    const fetchImages = () => {
      const dbRef = firebase.database().ref('imageSlidder');
      dbRef.on('value', async (snapshot) => {
        const imgs = snapshot.val();
        const imageLoadPromises = [];
        for (let id in imgs) {
          const imageUrl = imgs[id].imageUrl;
          const imagePromise = new Promise((resolve) => {
            const img = new Image();
            img.src = imageUrl;
            img.onload = () => resolve({ url: imageUrl, loaded: true });
            img.onerror = () => resolve({ url: imageUrl, loaded: false });
          });
          imageLoadPromises.push(imagePromise);
        }

        Promise.all(imageLoadPromises).then((loadedImages) => {
          const successfullyLoadedImages = loadedImages.filter(img => img.loaded);
          if (successfullyLoadedImages.length > 0) {
            setImages(successfullyLoadedImages);
          }
        });
      });
    };
    fetchImages();
  }, []);

  useEffect(() => {
    if (images.length > 1) {
      const interval = setInterval(() => {
        setCurrentIndex((currentIndex) => (currentIndex + 1) % images.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [images.length]);

  const nextImage = () => {
    setCurrentIndex(currentIndex => (currentIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex(currentIndex => (currentIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="relative w-full h-[84vh] overflow-hidden">
      {images.map((image, index) => (
        <div
          key={image.url}
          className={`absolute inset-0 transition-opacity duration-5000 ${index === currentIndex ? 'opacity-100' : 'opacity-0'} flex justify-center items-center`}
        >
          <img
            src={image.url}
            alt={`Slide ${index}`}
            className="w-full h-full object-cover"
            style={{
              transform: index === currentIndex ? 'scale(1.0)' : 'scale(1.1)',
              transition: 'transform 1s ease-in-out',
            }}
          />
        </div>
      ))}
      {images.length > 1 && (
        <>
          <button
            onClick={prevImage}
            className="absolute inset-y-1/2 left-0 z-10 text-white text-2xl p-2"
            aria-label="Previous image"
          >
            &#x276E;
          </button>
          <button
            onClick={nextImage}
            className="absolute inset-y-1/2 right-0 z-10 text-white text-2xl p-2"
            aria-label="Next image"
          >
            &#x276F;
          </button>
        </>
      )}
    </div>
  );
};

export default ImageSlider;
