import React from "react";
import Moto from "../../assets/image/affiliation.jpg";

const Affiliation = () => {
  return (
    <div className="mt-16 mb-10">
      <img src={Moto} alt="School motto banner" className="w-full h-[200px] md:h-[700px] object-cover" />
      <div className="bg-white p-8">
        <div className="mx-5 md:mx-20">
          <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom">
            Home &gt; About Us &gt; Affiliation
          </div>
          <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
            Affiliation
          </h1>
          <p className="text-base font-poppins">
            <span className="font-bold"> St. Thomas International School </span>{" "}
            is Recognized and Affiliated to the Central Board of Secondary
            Education (C.B.S.E), Delhi.<br/> (Affiliation No. 2131947, School No.
              71379)
          </p>
        </div>
      </div>
    </div>
  );
};

export default Affiliation;
