import React, { useEffect, useState } from "react";
import Achievement from "../assets/image/achievment.jpeg";
import {firebase} from "../db/Firebase"

const Achievments = () => {
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    const fetchEntries = async () => {
        const dbRef = firebase.database().ref('achievements');
        dbRef.on('value', (snapshot) => {
            const entriesData = snapshot.val();
            const loadedEntries = [];
            for (let id in entriesData) {
                loadedEntries.push({ id, ...entriesData[id] });
            }
            setEntries(loadedEntries.sort((a, b) => b.createdAt - a.createdAt));
        });
    };
    fetchEntries();
  }, []);

  const imageEntries = entries.filter(entry => entry.type === 'image');
  const textEntries = entries.filter(entry => entry.type === 'text');

  return (
    <div className="mt-20 mb-20 font-poppins">
      <img src={Achievement} alt="School motto banner" className="w-full h-[200px] md:h-[700px] object-cover" />
      <div className="max-w-screen-lg mx-5 md:mx-20">
        <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold mt-10 ">
          Home &gt; Achievements
        </div>
        <h1 className="text-5xl font-bold text-[#0c4680] py-5">
          Achievements
        </h1>

        {/* Image Grid */}
        <div className="grid grid-cols-1 md:grid-cols-6 gap-2 mb-8">
          {imageEntries.map((entry, index) => (
            <img key={entry.id} src={entry.content}  alt={`img ${index + 1}`}
             style={{
               width: '220px', height: 'auto',
               aspectRatio:"4/4"
                }} />
          ))}
        </div>

        {/* Text Content */}
        <ul className="list-disc pl-5 md:pl-10">
          {textEntries.map((entry, index) => (
            <li key={entry.id} className="mb-2">{entry.content}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Achievments;
