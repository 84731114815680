import React from "react";
import SecondaryImg from "../../assets/image/secondary.jpg"; 
import Table from "../../components/Table";

const Secondary = () => {
  return (
    <div className="mt-16 mb-10">
      <img
        src={SecondaryImg}
        alt="School motto banner for secondary"
        className="w-full h-[200px] md:h-[700px] object-cover "
      />
      <div className="bg-white p-8 mx-5 md:mx-10">
        <div className="max-w-screen-lg mx-auto">
          <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom">
            Home &gt; Academics &gt; Assessment &gt; CLASSES: III - XII
          </div>
          <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
            CLASSES: III - XII
          </h1>
          <p className="text-base font-poppins my-3">
            The secondary school curriculum is designed to nurture responsible, resilient and
            engaged learners. It provides a broad and balanced education that challenges students
            to apply their knowledge and skills in ways that are meaningful to them. This stage 
            of education is critical in preparing students for higher education and the world of work.
          </p>
          <p className="text-base font-poppins my-3">
            In addition to the academic rigor, the school emphasizes on the holistic development 
            of students through a variety of co-curricular activities, leadership opportunities, 
            and community service. The assessment framework is designed to provide continuous 
            feedback to students and parents, with a focus on formative and summative assessments 
            that encourage self-reflection and continual improvement.
          </p>
          <p className="text-base font-poppins my-3">
            Our aim is to create a nurturing environment where students are encouraged to
            become lifelong learners and global citizens, equipped with the skills and
            knowledge to contribute positively to society.
          </p>
          
        </div>
        <Table />
      </div>
    </div>
  );
};

export default Secondary;
