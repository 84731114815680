import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import {firebase} from "../db/Firebase"
import DefaultImage from "../assets/image/slide2.png";

const HomeModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [images, setImages] = useState([{ id: 'default', imageUrl: DefaultImage }]);

  useEffect(() => {
    setIsModalOpen(true);

    const fetchImages = async () => {
      const dbRef = firebase.database().ref('homeModal');
      dbRef.on('value', (snapshot) => {
        const imgs = snapshot.val();
        if (imgs) { // Check if imgs is not null or undefined
          const loadedImages = [];
          for (let id in imgs) {
            loadedImages.push({ id, ...imgs[id] });
          }
          if (loadedImages.length > 0) {
            setImages(loadedImages); 
          }
        }
      });
    
    };
    fetchImages();
    console.log("images",images)
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (!isModalOpen) return null;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    SwipeDirection:"left"
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center bg-gray-600 bg-opacity-80">
    <div className="relative top-12 border-4 border-black bg-white rounded-md" style={{ width: '500px', height: '590px' }}>
      <div className="flex justify-end bg-blue-500 p-2">
        <button onClick={closeModal} className="text-white hover:text-blue-200">
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div className="overflow-hidden border-8 border-green-700 h-[543px]"> {/* Set height to prevent overflow */}
        <Slider {...settings}>
          {images.map((img) => (
            <div key={img.id} className="flex justify-center flex-row">
              <img src={img.imageUrl} alt="Slide" className="h-full w-full object-cover" /> {/* Use object-cover to fill the space */}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  </div>
);
};

export default HomeModal;
