import React from "react";
import SportsImg from "../../assets/image/sports.jpg";

const Sports = () => {
  return (
    <div className="mt-16 mb-10">
      <img
        src={SportsImg}
        alt="School motto banner for secondary"
        className="w-full h-[200px] md:h-[500px] object-cover"
      />
      <div className="bg-white p-8 mx-5 md:mx-10">
        <div className="max-w-screen-lg mx-auto">
          <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom">
            Home &gt; BEYOND ACADEMICS &gt; Sports
          </div>
          <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
            Sports
          </h1>
          <p className="text-base font-poppins my-3">
            St. Thomas International School Varanasi takes pride in offering a
            broad spectrum of sports and physical activities, emphasizing the
            development of both mind and body for its students.
          </p>
          <h1 className="text-2xl font-bold font-custom py-4 ">Table Tennis</h1>
          <p className="text-base font-poppins my-3">
            Our facilities include an indoor Table Tennis area, equipped with
            top-notch STAG tables for older students and smaller tables for
            younger learners. Training is provided by a seasoned expert in the
            game, who has a history of coaching national competitors.
          </p>
          <h1 className="text-2xl font-bold font-custom py-4 ">Yoga</h1>
          <p className="text-base font-poppins my-3">
            Recognizing the holistic benefits of yoga, we've dedicated a serene
            Yoga Room for students to explore inner peace and physical
            flexibility. This tranquil space is adorned with yoga mats and
            charts of various asanas, promoting a calm and focused environment
            for personal growth.
          </p>
          <h1 className="text-2xl font-bold font-custom py-4 ">Football</h1>
          <p className="text-base font-poppins my-3">
            With a natural turf field and professional coaching, our football
            program offers students the opportunity to excel in team dynamics
            and physical endurance. St. Thomas International's team stands out
            in the Delhi-NCR region, with players achieving recognition at both
            national and international levels.
          </p>
          <h1 className="text-2xl font-bold font-custom py-4 ">Basketball</h1>
          <p className="text-base font-poppins my-3">
            Boasting an international standard synthetic court, our basketball
            program is supported by dedicated coaches training students for
            significant tournaments including the National School Games and
            Zonal Tournaments.
          </p>
          {/* <h1 className="text-2xl font-bold font-custom py-4 ">Gymnastics</h1>
          <p className="text-base font-poppins my-3">
            Our gymnastics room is outfitted with essential equipment, promoting
            both physical and psychosocial development. With professional
            coaching, students have the opportunity to participate in various
            levels of competition, from state to national.
          </p> */}
          <h1 className="text-2xl font-bold font-custom py-4 ">Cricket</h1>
          <p className="text-base font-poppins my-3">
            The cricket facilities feature a lush green ground and practice
            nets, catering to enthusiastic cricketers of our school. Our teams
            actively compete and succeed in tournaments across various levels.
          </p>
          <h1 className="text-2xl font-bold font-custom py-4 ">Chess Room</h1>
          <p className="text-base font-poppins my-3">
            An air-conditioned space with international standard chess-board
            tables facilitates analytical and strategic thinking. Our chess
            teams participate and excel in national level championships
          </p>
          <h1 className="text-2xl font-bold font-custom py-4 ">Skating Ring</h1>
          <p className="text-base font-poppins my-3">
            Our specialized skating arena, equipped with safety features, allows
            students to enjoy and excel in skating, coached by experienced
            professionals. Our skaters have earned accolades at both national
            and zonal competitions.
          </p>
          <h1 className="text-2xl font-bold font-custom py-4 ">Lawn Tennis</h1>
          <p className="text-base font-poppins my-3">
            Two synthetic international standard courts provide an excellent
            training ground under skilled coaches. Students have made notable
            achievements in National School Games and Zonal competitions.
          </p>
          <h1 className="text-2xl font-bold font-custom py-4 ">Karate</h1>
          <p className="text-base font-poppins my-3">
            Our Karate training area is equipped with the necessary gear for
            students to learn this art under the guidance of
            expert coaches. Participants have represented our school in various championships successfully.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Sports;
