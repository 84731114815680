import React from "react";
import { Link } from "react-router-dom";

const AdmissionSenior = () => {
  return (
    <div className="mt-20 mb-20 font-poppins">
      <div className="w-full h-32 bg-[#0c4680]"></div>
      <div className="max-w-screen-lg mx-5 md:mx-20">
        <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom mt-10">
          Home &gt; ADMISSIONS &gt; XI
        </div>
        <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
          XI
        </h1>
        <div className="flex flex-col justify-center items-center gap-5">
          
          <Link to="/admission/registration">
            <span className="text-base text-[#0c4680]  font-bold font-custom border-2 border-[#0c4680] px-10 p-1 bg-[#e77814] hover:cursor-pointer  hover:underline ">
              Click Here To Register Online
            </span>
          </Link>
        </div>
        <div className="flex flex-col">
        <span className="text-sm font-bold pt-5 text-[#0000cd] underline font-custom">CRITERIA</span>
        <span className="text-sm font-semibold py-1 ">The admission to class XI is subject to the declaration of CBSE Board result and the availability of seats. </span>
        <ul className="list-disc font-semibold pl-10 py-2">
            <li className=" text-sm font-semibold py-2">The candidate should have been studying in a school affiliated to CBSE or any other recognized Board of Secondary Education.</li>
            <li className=" text-sm font-semibold py-2">Student’s performance in Pre-Board/ Board examination and Entrance Test will be the criteria for admission.</li>
            <li className=" text-sm font-semibold py-2">Merit will be the sole criteria for allotment of streams.</li>
            <li className=" text-sm font-semibold py-2">Admission to class XI will be provisional and will be regularised after the declaration of result.</li>
            <li className=" text-sm font-semibold py-2">The candidate should produce the relevant documents as follows:</li>
            <ul className="pl-8 list-disc font-semibold">
                <li className=" text-sm font-semibold py-2">Either Report card of Class IX and Pre-Board result of Class X or Mark sheet of Class X</li>
                <li className=" text-sm font-semibold py-2">Certificates of special achievements- Sports, Co-Curricular activities, Scholarship Examination etc., if any.</li>
                <li className=" text-sm font-semibold py-2">In case of sibling (studying in St. Thomas International School, Sarnath Varanasi)- Please bring the photo copy of latest Fee Bill.</li>
                <li className=" text-sm font-semibold py-2">Birth certificate of the child issued by Municipal Corporation or equivalent authority.</li>
                <li className=" text-sm font-semibold py-2">Proof of Residence (MTNL bill/ Passport/ Driving License/ Voter I card/ Electricity/ Water Bill/ Rent Agreement), please submit the latest copy of the bill.</li>
                <li className=" text-sm font-semibold py-2">Original Character Certificate/ Code of Conduct Certificate duly signed by the Principal of the last school attended.</li>
            </ul>
            <li className=" text-sm font-semibold py-2">Any candidate migrating from a school in a foreign country, which is not affiliated to CBSE, shall be eligible for admission only on producing equivalent certificate obtained from CBSE and fulfilment of other conditions laid down by the school/CBSE.</li>
        </ul>
        <span className="text-sm font-bold pt-5 text-[#0000cd] underline font-custom">STREAMS OFFERED</span>
        <span className="text-sm font-semibold py-1 pl-8 ">The streams offered by the school are:</span>
<ul className="px-16">
    <li className="text-xs font-custom font-bold py-1">SCIENCE &rarr;Medical and Non – Medical</li>
    <li className="text-xs font-custom font-bold py-1">COMMERCE</li>
    <li className="text-xs font-custom font-bold py-1">HUMANITIES</li>
</ul>
        </div>
      </div>
      
    </div>
  );
};

export default AdmissionSenior;
