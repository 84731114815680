import React from 'react';
import Scanner from "../assets/image/Paytm.jpeg";

const PaymentModal = ({ closeModal }) => {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Pay Online
          </h3>
          <div className="mt-2 px-7 py-3">
            <img src={Scanner} alt="Scanner" className="mx-auto" />
            <p className="text-sm text-gray-500">
              Scan the QR code with Paytm to pay
            </p>
          </div>
          <div className="items-center px-4 py-3">
            <button
              id="ok-btn"
              className="px-4 py-2 bg-gray-800 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
