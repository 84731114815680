import React, { useState } from "react";
import PaymentModal from "./PaymentModal";

const OnLineFee = () => {
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const handlePayment = () => {
    setIsPaymentModalOpen(true);
  };
  return (
    <div className="mt-16 mb-10 ">
      <div className="w-full h-32 bg-[#0c4680]"></div>{" "}
      <div className="bg-white p-8">
        <div className="mx-5 md:mx-20">
          <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom">
            Home &gt; Online Fee Payment
          </div>
          <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
            Online Fee Payment
          </h1>
          <div className="middle-content mb-0 mb-md-5 font-poppins">
            <div className="container mx-auto">
              <div className="lg:flex lg:justify-between">
                <div className="lg:w-3/4">
                  <section className="section-inner">
                    <article>
                      <div className="text-content">
                        <div className="list-style">
                          <ul>
                            <li>
                              <h4 className="theme-color mt-3 md:mt-4 font-semibold">
                                Fee Payment Options
                              </h4>
                              <div className="table-responsive mt-4">
                                <table className="table-auto w-full border-collapse border border-gray-300">
                                  <tbody>
                                    <tr className="bg-gray-100 border-b border-gray-300">
                                      <td className="px-4 py-2 font-semibold">
                                        Details
                                      </td>
                                      <td className="px-4 py-2 font-semibold">
                                        Information
                                      </td>
                                    </tr>
                                    <tr className="bg-white border-b border-gray-300">
                                      <td className="px-4 py-2 font-semibold">
                                        School Account No.
                                      </td>
                                      <td className="px-4 py-2">3513582235</td>
                                    </tr>
                                    <tr className="bg-gray-100 border-b border-gray-300">
                                      <td className="px-4 py-2 font-semibold">
                                        Beneficiary Name
                                      </td>
                                      <td className="px-4 py-2">
                                        ST. THOMAS INTERNATIONAL SCHOOL
                                      </td>
                                    </tr>
                                    <tr className="bg-white border-b border-gray-300">
                                      <td className="px-4 py-2 font-semibold">
                                        Bank Name
                                      </td>
                                      <td className="px-4 py-2">
                                        CENTRAL BANK OF INDIA
                                      </td>
                                    </tr>
                                    <tr className="bg-gray-100 border-b border-gray-300">
                                      <td className="px-4 py-2 font-semibold">
                                        IFSC
                                      </td>
                                      <td className="px-4 py-2">CBIN0283076</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div>
                                  <div className="input-field">
                                    <button
                                      onClick={handlePayment}
                                      className="btn border p-2 w-full mt-6 hover:bg-blue-700 hover:text-white"
                                    >
                                      Pay Online
                                    </button>
                                  </div>
                                </div>

                                {/* Payment Modal */}
                                {isPaymentModalOpen && (
                                  <PaymentModal
                                    closeModal={() =>
                                      setIsPaymentModalOpen(false)
                                    }
                                  />
                                )}
                              </div>
                            </li>
                           
                            <li className="mt-8">
                              <h4 className="theme-color mt-3 md:mt-4 font-semibold">
                                Rules and Regulations
                              </h4>
                              <ul className="list-disc list-inside mt-4">
                              <li className="text-blue-900">
                              After Successful payment of Fee Kindly give a
                              missed call on This no. 9415101693
                            </li>
                            <li className="text-blue-900">
                              Kindly share Payement Slip on This no.9415101693
                              via Whatsapp
                            </li>
                                <li>
                                  Bank charges, if any, levied by the bank on
                                  inward/outward fee remittance shall be borne
                                  by the parent.
                                </li>
                                <li>
                                  Once the student has been accepted for
                                  admission, all payments are to be made
                                  immediately to confirm the admission.
                                </li>
                                <li>
                                  The Fees are raised every year by
                                  approximately 10% to cover the increase in
                                  staff salaries/inflation.
                                </li>
                                <li>
                                  The submission of 'Application for
                                  Registration' form and/or Registration Fee
                                  does not guarantee admission.
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </article>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnLineFee;
