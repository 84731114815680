import Public from "../../assets/image/tour.jpg";



const PublicDisclosure = () => {
  const data = [
    {
      "id": 1,
      "title": "Copy of Affiliation Letter",
      "type": "pdf",
      "content": "mpd/Affiliation_letter .pdf"
    },
    {
      "id": 2,
      "title": "Copy of Societies",
      "type": "pdf",
      "content": "mpd/society-certificate.pdf" 
    },
    {
      "id": 3,
      "title": "Copy of NOC (State Government)",
      "type": "pdf",
      "content":"mpd/noc.pdf"
    },
    {
      "id": 4,
      "title": "Copy of Recognition Certificate",
      "type": "pdf",
      "content":'mpd/recognition_certificate.pdf'
    },
    {
      "id": 5,
      "title": "Copy of Valid Building Safety Certificate",
      "type": "pdf",
      "content": "mpd/building_certificate.pdf"
    },
    {
      "id": 6,
      "title": "Copy of Valid Fire Safety Certificate",
      "type": "pdf",
      "content":"mpd/fire_certificate.pdf"
    },
    {
      "id": 7,
      "title": "Copy of Self Certification",
      "type": "pdf",
      "content": "mpd/Self-certification.pdf"
    },
    {
      "id": 8,
      "title": "Copy of Valid Water Health and Sanitation Certificates",
      "type": "pdf",
      "content": "mpd/Safe-drinking-water-and-sanitary-certificate.pdf"
    },
    {
      "id": 9,
      "title": "Fee Structure of School",
      "type": "pdf",
      "content":"mpd/Fee_Structure.pdf"
    },
    {
      "id": 10,
      "title": "Annual Academic Calendar",
      "type": "pdf",
      "content": "mpd/academic_calender.pdf"
    },
    {
      "id": 11,
      "title": "List of School Management Committee (SMC)",
      "type": "pdf",
      "content": "mpd/SMC.pdf"
    },
    {
      "id": 12,
      "title": "List of Parent Teacher Association",
      "type": "pdf",
      "content": "mpd/pta_member.pdf"
    },
    {
      "id": 13,
      "title": "Last Three Years Result of Board Exam",
      "type": "pdf",
      "content":"mpd/result_3_year.pdf"
    },
    {
      "id": 14,
      "title": "Mandatory Public Disclosure/ APPENDIX - IX",
      "type": "pdf",
      "content":"mpd/Mandatory-Disclosure-Details_SARAS_5.0.pdf"
    },
    {
      "id": 15,
      "title": "ST Thomas Self Certification  ",
      "type": "pdf",
      "content":"mpd/Self_certification.pdf"
    },
    {
      "id": 16,
      "title": "Appendix IX Section",
      "type": "pdf",
      "content":"mpd/appendix_ix_section.pdf"
    },
  
  ]
  return (
    <div className="mt-20 mb-20 font-poppins">
       <img
        src={Public}
        alt="School motto banner"
        className="w-full h-[200px] md:h-[700px]  object-cover"
      />
      <div className="max-w-screen-lg mx-5 md:mx-20">
        <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom mt-10">
          Home &gt; Mandatory Public Disclosure
        </div>
        <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 ">
        Mandatory Public Disclosure
        </h1>
        <div className="container mx-auto px-4">
            <Section title="A : GENERAL INFORMATION :">
                <InfoTable data={data} />
            </Section>

        </div>
       
      </div>
      
    </div>
    
  );
};
const Section = ({ title, children }) => (
    <div className="mb-8">
        <h4 className="mb-4 text-lg font-semibold text-white">{title}</h4>
        {children}
    </div>
);

const InfoTable = ({  data }) => (
    <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-black">
        <thead className="text-xs text-white uppercase bg-[#0c4680]">
          <tr>
            <th scope="col" className="py-3 px-6">Sr. No.</th>
            <th scope="col" className="py-3 px-6">Information</th>
            <th scope="col" className="py-3 px-6">Details</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className="bg-white border-b">
              <td className="py-4 px-6">{index + 1}</td>
              <td className="py-4 px-6">{item.title}</td>
              <td className="py-4 px-6 text-uppercase">
            
                {item.type === 'pdf' && (
                  <a href={`${item.content}`} target="_blank" rel="noopener noreferrer">View PDF</a>
                )} 
            
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
  



export default PublicDisclosure;
