import React, { useEffect, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../assets/image/logo.png";
import { firebase } from "../db/Firebase";

const navigationItems = [
  { main: "HOME", to: "/" },
  {
    main: "ABOUT US",
    subItems: [
      { name: "School Motto", to: "/about/schoolMoto" },
      { name: "Vision & Mission", to: "/about/vission-mission" },
      { name: "Management", to: "/about/management" },
      { name: "Chairman", to: "/about/chairman" },
      { name: "Principal", to: "/about/principal" },
      { name: "Affiliation", to: "/about/affiliation" },
      { name: "Faculty", to: "/about/faculty" },
      {
        name: "Mandatory Public Disclosure",
        to: "/mpd",
      },
    ],
  },

  {
    main: "ACADEMICS",
    subItems: [
      { name: "Curriculum", to: "/academics/curriculam" },
      { name: "Teaching Methodology", to: "/academics/teaching-methology" },
      { name: "Assessment", to: "/academics/assessement/pre-primary" },
      { name: "Pre-Primary", to: "academics/assessement/pre-primary" },
      { name: "Classes: I - II", to: "/academics/assessement/primary" },
      { name: "Classes: III - XII", to: "/academics/assessement/secondary" },
      { name: "E-learning", to: "/academics/e-learning" },
    ],
  },

  {
    main: "BEYOND ACADEMICS",
    subItems: [
      {
        name: "Co-curricular Activities",
        to: "/beyond-academics/co-curricular",
      },
      { name: "Community Service", to: "/beyond-academics/community" },
      { name: "Clubs", to: "/beyond-academics/clubs" },
      { name: "Student Council", to: "/beyond-academics/student-counsil" },
      { name: "Sports", to: "/beyond-academics/sports" },
      { name: "Visits & Excursions", to: "beyond-academics/visits" },
    ],
  },

  {
    main: "INFRASTRUCTURE",
    subItems: [{ name: "Facilities", to: "/infrastructure/facilities" }],
  },
  {
    main: "ADMISSION",
    subItems: [
      { name: "NURSERY", to: "/admission/nursery" },
      { name: "KG-IX", to: "/admission/kg-ix" },
      { name: "XI", to: "/admission/xi" },
      { name: "Fee Structure" },
    ],
  },
  {
    main: "CIRCULARS",
    to: "/circulars",
  },
];
function ThirdNav() {
  const [isOpen, setOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isSticky, setSticky] = useState(false);
  const [fee, setFee] = useState([]);

  const handleScroll = () => {
    const scrollPosition = window.pageYOffset;
    const navbarHeight = 200;

    if (scrollPosition >= navbarHeight) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const feeRef = firebase.database().ref("fee");
    feeRef.on("value", (snapshot) => {
      const fee = snapshot.val();
      const feeList = [];
      for (let id in fee) {
        feeList.push({ id, ...fee[id] });
      }
      setFee(feeList );
    });
    return () => feeRef.off();
  }, [fee]);

  const toggleNav = () => setOpen(!isOpen);
  const toggleDropdown = (index) => {
    if (activeDropdown === index) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(index);
    }
  };
  const handleNavLinkClick = (index) => {
    setActiveDropdown(null);
    setOpen(false);
  };
  const handleLoginClick = () => {
    window.open("https://admin.stis.ac.in/", "_blank");
  };

  const navbarStyle = isSticky
    ? {
        position: "fixed",
        top: 0,
        width: "100%",
        height: "70px",
        zIndex: 1000,
        backgroundColor: "white",
      }
    : {
        position: "absolute",
      };
 
  const handleFeeClick = () => {
    const feePdfUrl = fee[0]?.pdf;
    if (feePdfUrl) {
      window.open(feePdfUrl, "_blank");
    }
  };
  return (
    <>
      <nav
        className={`flex list-none justify-between z-10 bg-transparent w-full ${
          isSticky ? "shadow-md" : ""
        }`}
        style={navbarStyle}
      >
        <div
          className="relative bg-[#0c4680] text-white text-center  w-64 pt-2 md:pt-1 md:w-[22rem] z-20 h-20 md:h-32"
          style={{ clipPath: "polygon(0 0, 100% 0%, 90% 100%, 0% 100%)" }}
        >
          <div className="flex  justify-start ">
            <Link to="/">
              <img
                src={logo}
                alt="St. Thomas School Logo"
                className="mx-auto h-16 md:h-32 md:pb-3 object-cover pl-2"
              />
            </Link>
          </div>
        </div>
        <div className=" flex-row-reverse md:flex-row flex justify-around md:mr-12">
          <ul className="hidden list-none md:hidden lg:flex  items-center h-12 md:mt-2">
            {navigationItems.map((dropdown, index) => (
              <li
                key={index}
                className="relative group mr-5 text-base font-custom  leading-5"
              >
                {dropdown.to ? (
                  <Link
                    to={dropdown.to}
                    className="px-2 py-2 hover:text-sky-700 focus:bg-gray-300 font-custom"
                  >
                    {dropdown.main}
                  </Link>
                ) : (
                  <button
                    className="px-2 py-2 hover:text-sky-700 focus:bg-gray-300"
                    onClick={() => toggleDropdown(index)}
                  >
                    {dropdown.main}
                  </button>
                )}
                {dropdown.subItems && dropdown.subItems.length > 0 && (
                  <ul className="absolute hidden group-hover:block bg-white shadow-md whitespace-nowrap text-blue-500">
                    {dropdown.subItems.map((subItem, subIndex) => (
                      <li
                        key={subIndex}
                        className="px-3 py-2  hover:bg-gray-100"
                      >
                        {subItem.name === "Fee Structure" ? (
                          <button
                            onClick={handleFeeClick}
                            className="w-full text-left px-4 py-2 hover:bg-gray-100"
                          >
                            {subItem.name}
                          </button>
                        ) : (
                          <Link
                            to={subItem.to}
                            className="w-full text-left px-4 py-2 hover:bg-gray-100"
                            onClick={() => handleNavLinkClick(index)}
                          >
                            {subItem.name}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          <div className=" flex items-center h-12 md:gap-2 gap-5 md:mt-2 mr-10">
            <button
              className="bg-[#0c4680] text-base text-white font-semibold h-8 px-5  md:mt-0"
              onClick={handleLoginClick}
              target="_blank"
            >
              Login
            </button>
            <button className="text-black lg:hidden  z-40" onClick={toggleNav}>
              {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>
        </div>
      </nav>
      {isOpen && (
        <div className="relative navbar-class">
          <div className="absolute right-0 top-24 w-1/2 md:w-64 bg-white shadow-lg z-50">
            <ul className="flex flex-col">
              {navigationItems.map((item, index) => (
                <React.Fragment key={index}>
                  <li className="border-b border-gray-200">
                    {item.to ? (
                      <Link
                        to={item.to}
                        className="w-full text-left px-4 py-2 hover:bg-gray-100"
                        onClick={() => handleNavLinkClick(index)}
                      >
                        {item.main}
                      </Link>
                    ) : (
                      <button
                        className="w-full text-left px-4 py-2 hover:bg-gray-100"
                        onClick={() => toggleDropdown(index)}
                      >
                        {item.main}
                      </button>
                    )}
                  </li>
                  {activeDropdown === index && item.subItems && (
                    <ul className="bg-gray-50">
                      {item.subItems.map((subItem, subIndex) => (
                        <li
                          key={subIndex}
                          className="px-4 py-2 hover:bg-gray-200"
                        >
                          {subItem.name === "Fee Structure" ? (
                            <a
                              href={fee}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="w-full text-left px-4 py-2 hover:bg-gray-100"
                            >
                              {subItem.name}
                            </a>
                          ) : (
                            <Link
                              to={subItem.to}
                              onClick={() => handleNavLinkClick(index)}
                            >
                              {subItem.name}
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </React.Fragment>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default ThirdNav;
