import React, { useEffect, useState } from "react";
import SecondaryMotto from "../../assets/image/primary.jpg";
import {firebase} from "../../db/Firebase"

const Nursery = () => {
  const [fee, setFee] = useState([]);


  useEffect(() => {
      const feeRef = firebase.database().ref('fee');
      feeRef.on('value', (snapshot) => {
        const fee = snapshot.val();
        const feeList = [];
        for (let id in fee) {
          feeList.push({ id, ...fee[id] });
        }
        setFee(feeList);

      });
    }, []);
  return (
    <div className="mt-16 mb-10 font-poppins">
      <img
        src={SecondaryMotto}
        alt="School motto banner for secondary"
        className="w-full h-[200px] md:h-[500px] object-cover"
      />
      <div className="bg-white p-8 mx-5 md:mx-10">
        <div className="max-w-screen-lg mx-auto">
          <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom">
            Home &gt; Admission &gt; Nursery
          </div>
          <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
            NURSERY
          </h1>
          <div className="container mx-auto p-6">
            <div className="bg-white  px-8 pt-6 pb-8 mb-4">
              <h1 className="block text-gray-700 font-bold mb-2 text-xl text-center underline">
                REGISTRATION FOR CLASS NURSERY (SESSION:2024-25)
              </h1>
              <div className="mb-4 flex justify-center">
                <ul className="list-none border-1 w-1/2 border-[#808080] item-center text-center font-semibold text-sm">
                  <li className="text-[#4b0082] hover:underline border border-[#808080]">
                    NURSERY REGISTRATION - TIMELINE & CRITERIA (2024-25)
                  </li>
                  <li className="text-[#4b0082] hover:underline border border-[#808080]">
                    Document Verification - Nursery (2024-25)
                  </li>
                  <li className="text-[#4b0082] hover:underline border border-[#808080]">
                    List of all registered applicants who applied under open
                    seats
                  </li>
                  <li className="text-[#4b0082] hover:underline border border-[#808080]">
                    List of all applicants as per point system who applied under
                    open seats
                  </li>
                  <li className="text-[#4b0082] hover:underline border border-[#808080]">
                    Circular for DRAW of LOTS
                  </li>
                  <li className="text-[#4b0082] hover:underline border border-[#808080] bg-[#ff8c00] ">
                    1st Confirmed list - Nursery [Open seats]
                  </li>
                  <li className="text-[#4b0082] hover:underline border border-[#808080] bg-[#dda0dd] ">
                    1st Waiting list - Nursery [Open seats]
                  </li>
                  <li className="text-[#4b0082] hover:underline border border-[#808080]">
                    Circular for DRAW of LOTS -2nd list
                  </li>
                  <li className="text-[#4b0082] hover:underline border border-[#808080] bg-[#ff8c00]">
                    2nd Confirmed list-Nursery [open seats]
                  </li>
                  <li className="text-[#4b0082] hover:underline border border-[#808080] bg-[#dda0dd] ">
                    2nd Waiting list-Nursery [open seats]
                  </li>
                </ul>
              </div>

              <div className="mb-6 py-10 ">
                <ul className="border-2 font-poppins text-sm border-[#808080] w-1/3 flex justify-between px-1">
                  <li className="font-semibold">FEE STRUCTURE FOR NURSERY (2023-2024)</li>
                 {fee.map((fee)=>(
                   <li class="text-red-600"><a href={fee.pdf} target="_blank" rel="noreferrer">Click to View</a></li>
                 ))}
       
                </ul>
              </div>
              <div>
                <span className="font-bold text-sm">AGE ELIGIBILITY:</span>
                <p className="text-sm">
                  * The age eligibility for admission in any class is as per the
                  guidelines issued by Hon’ble Directorate of Education, New
                  Delhi.
                </p>
                <p className="text-sm">
                  * For any queries, send an email to
                stis.varanasi@gmail.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nursery;
