import React from 'react'
import { Link } from 'react-router-dom'

function SecondNav() {
  return (
    <nav className="bg-amber-600 flex justify-center h-10 items-center ">
    <marquee>
      <Link to="/admission/registration">
        <p className="text-white items-center " >
          CLICK HERE FOR ONLINE REGISTRATION
        </p>
      </Link>
    </marquee>
  </nav>
  )
}

export default SecondNav