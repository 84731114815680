import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import feeStructure from "../assets/pdf/fee.pdf";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="bg-black text-white font-custom">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 ">
        <div className="grid grid-cols-2 md:grid-cols-7 gap-1">
          <div className="col-span-2 md:col-span-2">
            <h5 className="text-base font-bold mb-2  uppercase text-gray-300">
              ABOUT US
            </h5>
            <ul className="space-y-1 text-sm text-zinc-500 ">
              <li className="hover:text-white">
                <Link to="/about/schoolMoto">School Motto</Link>
              </li>
              <li className="hover:text-white">
                <Link to="/about/vission-mission">Vision & Mission</Link>
              </li>
              <li className="hover:text-white">
                <Link to="/about/management">Management</Link>
              </li>
              <li className="hover:text-white">
                <Link to="/about/chairman">Chairman</Link>
              </li>
              <li className="hover:text-white">
                <Link to="/about/affiliation">Affiliation</Link>
              </li>
              <li className="hover:text-white">
                <Link to="/about/faculty">Faculty</Link>
              </li>
              <li className="hover:text-white">
                <Link to="/mpd">
                  Mandatory Public Disclosure
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-span-2 md:col-span-1">
            <h5 className="text-base font-bold mb-2  uppercase text-gray-300">
              Academics
            </h5>
            <ul className="space-y-1 text-sm text-zinc-500">
              <li className="hover:text-white">
                <Link to="/academics/curriculam">Curriculum</Link>
              </li>
              <li className="hover:text-white">
                <Link to="/academics/teaching-methology">
                  Teaching Methodology
                </Link>
              </li>
              <li className="hover:text-white">
                <Link to="/academics/assessement/pre-primary">Assessment</Link>
              </li>
              <li className="hover:text-white">
                <Link to="/academics/assessement/pre-primary">Pre-Primary</Link>
              </li>
              <li className="hover:text-white">
                <Link to="/academics/assessement/primary">Classes: I - II</Link>
              </li>
              <li className="hover:text-white">
                <Link to="/academics/assessement/secondary">
                  Classes: III - XII
                </Link>
              </li>
              <li className="hover:text-white">
                <Link to="/academics/e-learning">E-Learning</Link>
              </li>
            </ul>
          </div>
          <div className="col-span-2 md:col-span-1">
            <h5 className="text-base font-bold mb-2  uppercase text-gray-300">
              BEYOND ACADEMICS
            </h5>
            <ul className="space-y-1 text-sm text-zinc-500">
              <li className="hover:text-white">
                <Link to="/beyond-academics/co-curricular">
                  Co-curricular Activities
                </Link>
              </li>
              <li className="hover:text-white">
                <Link to="/beyond-academics/community">Community Service</Link>
              </li>
              <li className="hover:text-white">
                <Link to="/beyond-academics/clubs">Clubs</Link>
              </li>
              <li className="hover:text-white">
                <Link to="/beyond-academics/visits">Visits & Excursions</Link>
              </li>
              <li className="hover:text-white">
                <Link to="beyond-academics/student-counsil">
                  Student Council
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-span-2 md:col-span-1">
            <h5 className="text-base font-bold mb-2  uppercase text-gray-300">
              Admission
            </h5>
            <ul className="space-y-1 text-sm text-zinc-500">
              <li className="hover:text-white">
                <Link to="/admission/nursery">NURSERY</Link>
              </li>
              <li className="hover:text-white">
                <Link to="/admission/kg-ix">KG-IX</Link>
              </li>
              <li className="hover:text-white">
                <Link to="/admission/xi">XI</Link>
              </li>
              <li className="hover:text-white">
                <a href={feeStructure} target="blank">Fee Structure</a>
              </li>
            </ul>
          </div>

          <div className="col-span-2 md:col-span-2 flex flex-col justify-between md:ml-5">
            <div className="flex flex-col">
              <h5 className="text-base font-bold mb-2 uppercase text-gray-300">
                St. Thomas International School
              </h5>
              <span className="text-zinc-500">Damodarpur, Munari Road</span>
              <span className="text-zinc-500">Sarnath Varanasi - 221007</span>
            </div>
            <div className="flex justify-start gap-12">
              <Link to="https://www.facebook.com/share/WHkTCRdQtuenzatZ/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer">
                <div className="border-2 border-blue-800 p-1 rounded-lg inline-flex justify-center items-center">
                  <FontAwesomeIcon icon={faFacebook} size="2x" />
                </div>
              </Link>
              <Link to="" target="_blank" rel="noopener noreferrer">
                <div className="border-2 border-red-600 p-1 rounded-lg inline-flex justify-center items-center">
                  <FontAwesomeIcon icon={faYoutube} size="2x" />
                </div>
              </Link>
              <Link to="https://www.instagram.com/stisvns?igsh=MWMwZ3F6bXNnNHo2bw==" target="_blank" rel="noopener noreferrer">
                <div className="border-2 border-red-600 p-1 rounded-lg inline-flex justify-center items-center ">
                  <FontAwesomeIcon icon={faInstagram} size="2x" />
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-700 mt-8 pt-4 text-zinc-500">
          <p className="text-center text-sm">&copy; 2024 All Rights Reserved</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
