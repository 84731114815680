import React, { useEffect, useState } from "react";
import MagazineImg from "../assets/image/magazine.jpg";
import {firebase} from "../db/Firebase"


const Magazine = () => {
  const [magazine, setMagazine] = useState([]);

  useEffect(() => {
      const magazineRef = firebase.database().ref('magazine');
      magazineRef.on('value', (snapshot) => {
        const magazine = snapshot.val();
        const magazineList = [];
        for (let id in magazine) {
          magazineList.push({ id, ...magazine[id] });
        }
        setMagazine(magazineList);
      });
    }, []);
  return (
    <div className="mt-20 mb-20 font-poppins">
      <img src={MagazineImg} alt="School motto banner" className="w-full h-[200px] md:h-[500px]" />
      <div className="max-w-screen-lg mx-5 md:mx-20">
        <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom mt-10">
          Home &gt; Magazine 
        </div>
        <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 ">
        Magazine
        </h1>
            
         
        <div className="flex flex-col items-start">
          {magazine.map((circular) => (
            <a 
              key={circular.id} 
              href={circular.pdf} 
              target="_blank" 
              rel="noreferrer" 
              className="text-base text-[#0c4680] font-bold p-1 cursor-pointer inline-block"
            >
              {circular.title}
            </a>
          ))}
        </div>
      </div>
      
    </div>
  );
};

export default Magazine;
