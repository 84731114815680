import React, { useEffect, useState } from "react";
import { firebase } from "../db/Firebase";

const itemsPerPage = 12; // Number of items per page

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [images, setImages] = useState([]);
  useEffect(() => {
    const fetchImages = async () => {
      const dbRef = firebase.database().ref("gallery");
      dbRef.on("value", (snapshot) => {
        const imgs = snapshot.val();
        const loadedImages = [];
        for (let id in imgs) {
          loadedImages.push({
            src: imgs[id].url || imgs[id].imageUrl,
            alt: `Image ${id}`,
          });
        }
        console.log(loadedImages);
        setImages(loadedImages);
      });
    };
    fetchImages();
  }, []);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = images.slice(firstIndex, lastIndex);

  const totalPages = Math.ceil(images.length / itemsPerPage);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="mt-20 mb-20 font-poppins">
      <div className="w-full h-32 bg-[#0c4680]"></div>
      <div className="max-w-screen-lg mx-5 md:mx-20">
        <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold mt-10">
          Home &gt; Gallery
        </div>
        <h1 className="text-5xl font-bold text-[#0c4680] py-5 md:py-10 ">
          Gallery
        </h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 border border-black p-5">
          {currentItems.map((image, index) => (
            <div
              key={index}
              className="overflow-hidden rounded-lg shadow-lg cursor-pointer hover:shadow-2xl transition duration-300 ease-in-out"
            >
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-full object-cover hover:scale-110 transition duration-300 ease-in-out"
                onClick={() => handleImageClick(image)}
              />
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-8">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => goToPage(index + 1)}
              className={`mx-2 p-2 ${
                currentPage === index + 1
                  ? "text-blue-600 font-bold"
                  : "text-gray-600"
              }`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
      {selectedImage && (
        <div
          className="fixed inset-0 z-50 flex justify-center items-center"
          onClick={handleClose}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-md"></div>
          <img
            src={selectedImage.src}
            alt={selectedImage.alt}
            className="max-w-full max-h-full p-4 relative z-10"
            onClick={handleClose}
          />
        </div>
      )}
    </div>
  );
};

export default Gallery;
