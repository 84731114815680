import React from "react";
import recruitement from "../assets/pdf/recruitment.pdf"
// import Vacancies from "../assets/image/vacancy.jpg";

const Vacancy = () => {
  return (
    <div className="mt-20 mb-20  font-customs">
      {/* <img src={Vacancies} alt="School motto banner" className="w-full h-[200px] md:h-[500px]" /> */}
      <div className="w-full h-32 bg-[#0c4680]"></div>

      <div className="max-w-screen-lg mx-5 md:mx-20">
        <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold mt-10 ">
          Home &gt; Vacancy
        </div>
        <h1 className="text-5xl font-bold text-[#0c4680] py-5 uppercase">
          Vacancy
        </h1>
        <div className="flex justify-around items-center bg-white p-4">
        
        <div className="pl-4 ">
       
          <ul className="w-full list-disc pl-5 mt-4 ">
            <li>Candidates should mail their updated resume at <a href="mailto: stis.varanasi@gmail.com" className="text-[#0000ff] font-bold text-base"> stis.varanasi@gmail.com</a></li>
            <span className="text-xl font-semibold text-center flex justify-center"> OR</span>
            <li className="ml-10 "> Download the <a href={recruitement} target="blank" className="text-xl text-red-700 font-semibold">Recruitment Form </a> and share the filled form <br/><a href="mailto: stis.varanasi@gmail.com" className="text-[#0000ff] font-bold text-base"> stis.varanasi@gmail.com</a></li>
            <li>The subject of the email should clearly specify <span className="text-xl font-bold"> "THE POST APPLIED FOR".</span></li>
            <li className="ml-10 "> Salary as per govt. norms. Higher salaries for deserving candidates </li>

          </ul>
        </div>
      </div>
     
      </div>
    </div>
  );
};

export default Vacancy;
