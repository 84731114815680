import React from "react";
import ElearingImg from "../../assets/image/elearning.jpg";

const Elearning = () => {
  return (
    <div className="mt-16 mb-10">
      <img
        src={ElearingImg}
        alt="School motto banner"
        className="w-full h-[200px] md:h-[700px] object-cover object-top"
      />
      <div className="bg-white p-8 mx-5 md:mx-10">
        <div className="max-w-screen-lg mx-auto">
          <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom">
            Home &gt; Academics &gt; E-Learning
          </div>
          <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
            E-Learning
          </h1>
          <p className="text-base font-poppins my-3">
            St. Thomas International School is at the forefront of an
            educational transformation. Our approach to teaching embraces
            dynamic activities designed to inspire positive growth in learners,
            fostering both excellence and proficiency.
          </p>
          <p className="text-base font-poppins my-3">
            "At St. Thomas International School, located in Varanasi, we are
            committed to providing exceptional learning experiences aimed at
            preparing young minds for the Knowledge Society. Our approach
            embraces the use of technology, including a Learning Management
            System (LMS) to revolutionize academia. This integration of
            technology paves the way for Artificial Intelligence, enhancing
            personalized and convenient learning experiences for our students.
            Our innovative learning model drives educational progress, breaking
            new ground in traditional learning methodologies."
          </p>
        </div>
      </div>
    </div>
  );
};

export default Elearning;
