import React from "react";
import { Link } from "react-router-dom";

const AdmissionKg = () => {
  return (
    <div className="mt-20 mb-20 font-poppins">
      <div className="w-full h-32 bg-[#0c4680]"></div>
      <div className="max-w-screen-lg mx-5 md:mx-20">
        <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom mt-10">
          Home &gt; ADMISSIONS &gt; KG-IX
        </div>
        <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
          KG-IX
        </h1>
        <div className="flex flex-col justify-center items-center gap-5">
          <span className="text-xl text-[#b22222]  font-bold font-custom underline ">
            REGISTRATION FOR CLASSES KG - IX (SESSION:2024-25)
          </span>
          <Link to="/admission/registration">
            <span className="text-base text-[#0c4680]  font-bold font-custom border-2 border-[#0c4680] px-10 p-1 bg-[#e77814] hover:cursor-pointer  hover:underline ">
              Click Here To Register Online
            </span>
          </Link>
        </div>
        <span className="text-sm font-semibold pt-5">AGE ELIGIBILITY:</span>
        <p className="text-sm font-poppins py-2">
          The age eligibility for admission in any class is as per the
          guidelines issued by Hon’ble Directorate of Education, New Delhi.{" "}
        </p>
        <p className="text-sm font-semibold pt-6">NOTE:</p>
        <ul className=" list-disc mx-8 ">
          <li>Final admission is subject to availability of seats.</li>
          <li>
            The admission will be provisional and will be regularised only upon
            submission of final report card and TC (Transfer Certificate) of the
            previous school.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdmissionKg;
