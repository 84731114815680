import React, { useEffect, useState } from "react";
import { FaFacebookF, FaInstagram, FaPhone, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import { firebase } from "../db/Firebase";

function FirstNav() {
  const [campusTour, setCampusTour] = useState([]);
  const [loading, setLoading] = useState(true);
  const phoneNumber = "0542-2595193";
  const handleCallClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };
  const facebookUrl =
    "https://www.facebook.com/share/WHkTCRdQtuenzatZ/?mibextid=qi2Omg";
  const instagramUrl =
    "https://www.instagram.com/stisvns?igsh=MWMwZ3F6bXNnNHo2bw==";
  const youtubeUrl = "";
  useEffect(() => {
    const campusTourRef = firebase.database().ref('campusTour');
    campusTourRef.on('value', (snapshot) => {
      const tours = snapshot.val();
      const toursList = [];
      for (let id in tours) {
        toursList.push({ id, ...tours[id] });
      }
      setCampusTour(toursList);
      setLoading(false); // Update loading state after fetching data
    });
  }, []);

  return (
    <nav className="bg-[#0c4680] pt-1 pr-5 pb-1 md:gap-14 flex flex-col justify-around text-white  items-center uppercase md:flex-row md:justify-around md:base lg:text-xl">
      <ul className="flex justify-around cursor-pointer items-center list-none gap-4 w-full text-sm mb-2 md:flex md:flex-row md:justify-around md:w-auto md:order-2 md:mb-0 md:text-xl">
        <li onClick={handleCallClick}>
          <FaPhone />
        </li>
        <li>
          <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
            <FaFacebookF />
          </a>
        </li>
        <li>
          <a href={youtubeUrl} target="_blank" rel="noopener noreferrer">
            <FaYoutube />
          </a>
        </li>
        <li>
          <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
        </li>
      </ul>
      <ul className="grid grid-cols-2 gap-2 w-full list-none pl-10 md:gap-8 text-xs sm:text-sm md:text-sm lg:text-xl md:flex md:flex-row md:justify-around md:w-auto leading-5">
      {loading || campusTour.length === 0 ? (
          <li>Campus Tour</li>
        ) : (
          campusTour.map((tour, index) => (
            <li key={index}>
              <Link to={tour.title} target="_blank">Campus Tour</Link>
            </li>
          ))
        )}
        <li>
          <Link to="/online-fee">Online fee Payment</Link>
        </li>
        <li>
          <Link to="/school-magazine">School Magazine</Link>
        </li>
        <li>
          <Link to="/school-toppers">School Toppers</Link>
        </li>
        <li>
          <Link to="/school-achievments">Achievements</Link>
        </li>
        <li>
          <Link to="/school-vacancy">Vacancy</Link>
        </li>
        <li>
          <Link to="/contact-us">Contact Us</Link>
        </li>
      </ul>
    </nav>
  );
}

export default FirstNav;
