import React from "react";
import ClubImg from "../../assets/image/club.jpg";

const Clubs = () => {
  return (
    <div className="mt-16 mb-10">
      <img
        src={ClubImg}
        alt="School motto banner for secondary"
        className="w-full h-[200px] md:h-[500px] object-cover"
      />
      <div className="bg-white p-8 mx-5 md:mx-10">
        <div className="max-w-screen-lg mx-auto">
          <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom">
            Home &gt; BEYOND ACADEMICS &gt; Clubs
          </div>
          <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
            Clubs
          </h1>

          <p className="text-base font-poppins my-3">
            At St. Thomas International School Varanasi, our vibrant school
            clubs play a pivotal role in broadening the educational journey of
            our students. These extracurricular groups are essential, offering a
            plethora of opportunities for students to shine beyond the
            traditional academic realm. Under the expert guidance of our
            dedicated educators, these clubs become a breeding ground for
            students to showcase their talents across a variety of domains..
          </p>
          <p className="text-xl my-3 font-bold">
          The objectives of having a club for the students are:
          </p>
          <ul className=" list-disc font-poppins ">
            <li className="ml-10 py-1">To cater to and nurture the academic, physical, and social facets of student development.</li>
            <li className="ml-10 py-1">To forge leadership qualities within students.</li>
            <li className="ml-10 py-1">To offer constructive channels for expressing innovative ideas and opinions.</li>
            <li className="ml-10 py-1">To inculcate the values of teamwork and accountability.</li>
            <li className="ml-10 py-1">To ignite a passion for activities that may turn into lifelong hobbies.</li>
            <li className="ml-10 py-1">To meet the holistic needs of students by providing a comprehensive developmental experience.</li>
          </ul>
          <p className="text-xl my-3 font-bold">
          The clubs offered at our school include:
          </p>
          <ul className=" list-disc text-base font-poppins ">
            <li className="ml-10 py-1 font-semibold">Robotics</li>
            <li className="ml-10 py-1 font-semibold">Public Speaking</li>
            <li className="ml-10 py-1 font-semibold">ECO Club</li>
            <li className="ml-10 py-1 font-semibold">Dance & Music</li>
            <li className="ml-10 py-1 font-semibold">Dramatics</li>
            <li className="ml-10 py-1 font-semibold">Digital Studies</li>
            <li className="ml-10 py-1 font-semibold">Community Service</li>
            <li className="ml-10 py-1 font-semibold">Astronomy</li>
            <li className="ml-10 py-1 font-semibold">Vedic Math</li>

          </ul>
        </div>
      </div>
    </div>
  );
};

export default Clubs;
