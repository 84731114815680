import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const EventSection = () => {
  const location = useLocation();
  const events = location.state?.events;
  const singleEvent = location.state?.event;
  const data = location?.state?.events
 
  
  const [selectedEvent, setSelectedEvent] = useState(
    singleEvent || (events && events.length > 0 ? events[0] : null)
  );
  const [selectedImage, setSelectedImage] = useState(
    selectedEvent?.images?.[0] || ""
  );

  useEffect(() => {
    if (selectedEvent?.images?.length > 1) {
      const intervalId = setInterval(() => {
        setSelectedImage((current) => {
          const currentIndex = selectedEvent.images.indexOf(current);
          const nextIndex =
            currentIndex + 1 === selectedEvent.images.length
              ? 0
              : currentIndex + 1;
          return selectedEvent.images[nextIndex];
        });
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [selectedEvent]);

  const hiddenScrollbarStyle = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  };

  const EventCard = ({ event, selectedImage, hiddenScrollbarStyle }) => (
    <div>
      <div className="flex justify-start gap-5 items-center pb-5 bg">
        <span className="text-xl font-bold text-blue-900 overflow-x-auto">{event.date}</span>
        <h2 className="text-xl font-bold">{event.title}</h2>
      </div>
      <div className="flex flex-col max-w-screen-2xl mx-auto border-2 border-black p-5">
        <div className="flex">
          <div className="w-1/4 flex flex-col">
            <div
              className="overflow-auto h-[75vh] p-2 border-r-2 border-gray-200"
              style={hiddenScrollbarStyle}
            >
              {event.images.map((image, index) => (
                <img
                  key={index}
                  className="mb-2 cursor-pointer w-full h-48 object-cover"
                  src={image}
                  alt={`Event ${event.title}`}
                  onClick={() => setSelectedImage(image)}
                />
              ))}
            </div>
          </div>
          <div className="w-3/4 flex flex-col">
            <div className="flex-1">
              <img
                className="w-full h-[75vh] object-cover"
                src={selectedImage}
                alt="Selected event"
              />
            </div>
          </div>
        </div>

        <div className="p-2">
          <p className="text-base">{event.description}</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="mt-20 mb-20 font-poppins">
      <div className="w-full h-32 bg-[#0c4680]"></div>
      <div className="max-w-screen-lg mx-5 md:mx-20">
        <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold mt-10">
          Home &gt; News & Events
        </div>

        <h1 className="text-5xl font-bold text-[#0c4680] py-5 md:py-10">
          {" "}
          News & Events
        </h1>

        {events && events.length > 1 ? (
          <div className="flex justify-start my-5 overflow-x-auto">
            {events.map((event, index) => (
              <button
                key={index}
                className={`p-2 m-1 ${
                  selectedEvent.id === event.id
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200"
                }`}
                onClick={() => {
                  setSelectedEvent(event);
                  setSelectedImage(event.images[0]);
                }}
              >
                {event.date}
              </button>
            ))}
          </div>
        ) : null}
        {selectedEvent ? (
          <EventCard
            event={selectedEvent}
            selectedImage={selectedImage}
            hiddenScrollbarStyle={hiddenScrollbarStyle}
          />
        ) : (
          <div>No Event Found</div>
        )}
      </div>
    </div>
  );
};

export default EventSection;
