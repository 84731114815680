import React from "react";
import Moto from "../../assets/image/Moto.jpeg";

const SchoolMotto = () => {
  return (
    <div className=" mt-16 mb-10">
      <img
        src={Moto}
        alt="School motto banner"
        className="w-full h-[200px] md:h-[500px] "
      />
      <div className="bg-white p-8">
        <div className="mx-5 md:mx-20">
          <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom">
            Home &gt; About Us &gt; School Motto
          </div>
          <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
            School Motto
          </h1>
          <p className="text-base font-poppins">
            St. THOMAS INTERNATIONAL SCHOOL aims to provide a nurturing,
            learning environment to enable students to become responsible
            citizens, life-long learners and good communicators through a
            challenging curriculum. The well reasearched and project based international 
            learning methodology of the school is designed to prepare
            students to excel in the world’s finest colleges and universities.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SchoolMotto;
