import React, { useEffect, useState } from 'react';
import { firebase } from "../db/Firebase";

const Circulars = () => {
  const [circulars, setCirculars] = useState([]);

  useEffect(() => {
    const circularRef = firebase.database().ref('circular');
    circularRef.on('value', (snapshot) => {
      const circularsData = snapshot.val();
      const circularList = [];
      for (let id in circularsData) {
        circularList.push({ id, ...circularsData[id] });
      }
      circularList.sort((a, b) => b.createdAt - a.createdAt);
              setCirculars(circularList);
    });
  }, []);

  return (
    <div className="mt-20 mb-20 font-poppins">
      <div className="w-full h-32 bg-[#0c4680]"></div>
      <div className="max-w-screen-lg mx-5 md:mx-20">
        <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold mt-10">
          Home &gt; Circulars
        </div>
        <h1 className="text-5xl font-bold text-[#0c4680] py-5">
          Circulars
        </h1>
        <p className="text-xl text-[#b22222] font-bold py-3">
          CIRCULARS: Session 2024-25
        </p>
        <div className="flex flex-col items-start">
          {circulars.map((circular) => (
            <a 
              key={circular.id} 
              href={circular.pdf} 
              target="_blank" 
              rel="noreferrer" 
              className="text-base text-[#0c4680] font-bold p-1 cursor-pointer inline-block"
            >
              {circular.title}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Circulars;
