import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {firebase} from "../db/Firebase"

const CalendarWithEvents = () => {
    const [value, onChange] = useState(new Date());
    const [events, setEvents] = useState([]);
    useEffect(() => {
        const eventsRef = firebase.database().ref('calendarWithEvents');
        eventsRef.on('value', (snapshot) => {
          const events = snapshot.val();
          const eventsList = [];
          for (let id in events) {
            eventsList.push({ id, ...events[id] });
          }
          eventsList.sort((a, b) => b.createdAt - a.createdAt);
          setEvents(eventsList);
          });
      }, []);

  const tileContent = ({ date, view }) => {
    if (view === "month") {
      const dayEvents = events.filter(
        (event) => new Date(event.date).toDateString() === date.toDateString()
      );
      return (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "2px",
            marginTop: "4px",
          }}
        >
          {dayEvents.map((event) => (
            <div
              key={event.id}
              className="bg-blue-500 text-white text-xs p-1 rounded"
              style={{
                backgroundColor: "#60a5fa",
                color: "#ffffff",
                fontSize: "0.75rem",
                padding: "0.25rem",
                borderRadius: "0.375rem",
              }}
            >
              {event.title}
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center py-12 "
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "3rem",
        paddingBottom: "3rem",
      }}
    >
      <Calendar
        onChange={onChange}
        value={value}
        className="border-none md:mt-28 mt-10"
        tileContent={tileContent}
      />
    </div>
  );
};

export default CalendarWithEvents;
