import React from "react";
import Publicvisit from "../../assets/image/tour.jpg";

const Visits = () => {
  return (
    <div className="mt-20 mb-20">
      <img
        src={Publicvisit}
        alt="School motto banner for secondary"
        className="w-full h-[200px] md:h-[700px] object-cover"
      />
      <div className="max-w-screen-lg mx-5 md:mx-20">
        <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom mt-10">
          Home &gt; About Us &gt; VISITS & EXCURSIONS
        </div>
        <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
          Visits & Excursions
        </h1>

        <p
          className="text-base font-poppins font-bold pb-5"
          style={{ fontStyle: "italic" }}
        >
          {" "}
          "Adventurers who dare greatly often achieve the most." - Ovid
        </p>
        <p className="text-base font-poppins py-5">
          At St. Thomas’ School International School Varanasi, there's a strong
          conviction that students remember best what they observe directly,
          somewhat of what they hear, and every single detail of what they
          experience personally. The educational landscape around the world has
          seen a remarkable evolution, with learning now expanding beyond the
          confines of traditional classroom spaces.
        </p>
        <p className="text-base font-poppins ">
          The institution is committed to delivering an educational experience
          that not only goes beyond the basic textbook curriculum but also
          incorporates practical knowledge about the world. Classroom learning
          is enhanced by firsthand experiences through a wide array of outdoor
          activities, including field trips and educational tours, which are
          regularly organized for various grades, ensuring a holistic
          understanding of subjects.
        </p>
      </div>
    </div>
  );
};

export default Visits;
