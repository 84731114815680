import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { firebase } from "../db/Firebase"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import exploreImage from "../assets/image/annualfunction.jpg"

const ImageCard = ({ imageUrl, title, path }) => (
  <Link to={path || "/gallery"} className="no-underline ">
    <div className="relative group w-64 h-64  bg-white rounded-full overflow-hidden border-2 border-gray-200 shadow flex items-center justify-center">
      <img
        src={imageUrl}
        alt={title}
        className="object-cover object-center w-full h-full transition-transform duration-300 ease-in-out"
      />
      <p className="absolute opacity-0 group-hover:opacity-100 text-white font-semibold text-center text-sm transition duration-300 ease-in-out transform group-hover:scale-110 z-10">
        {title}
      </p>
    </div>
  </Link>
);

const Explore = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      const dbRef = firebase.database().ref("explore");
      dbRef.on("value", (snapshot) => {
        const imgs = snapshot.val();
        const loadedImages = [];
        for (let id in imgs) {
          loadedImages.push({
            imageUrl: imgs[id].imageUrl,
            title: imgs[id].title || "Default Title",
            path: imgs[id].path || "/gallery",
          });
        }
        setImages(loadedImages);
      });
    };
    fetchImages();
  }, []);
  const isSecondRow = (index, arrayLength) => {
    return index >= 4 && index < Math.min(7, arrayLength);
  };
  return (
    <div class="sm:px-10 md:px-20 lg:px-20 bg-[#f7f1f1] px-10 pb-10">
      <h2 class="text-3xl text-[#0c4680] font-bold pb-20 pt-20 text-center md:px-10">
        EXPLORE ST. THOMAS INTERNATIONAL SCHOOL
      </h2>

      <div
        class={`grid ${
          images.length > 4
            ? "sm:grid-cols-4 md:grid-cols-4"
            : "sm:grid-cols-3 md:grid-cols-3"
        } gap-6 mb-6 md:px-20 justify-items-center`}
      >
        {images.map((image, index) => (
          <div
            key={index}
            class={`w-full ${
              isSecondRow(index, images.length)
                ? "flex justify-center md:pl-64"
                : "max-w-xs"
            }`}
          >
            <ImageCard
              imageUrl={image.imageUrl}
              title={image.title}
              path={image.path}
              onError={(e) => {
                e.target.src ={exploreImage};
              }}
            />
          </div>
        ))}
      </div>
      {images.length > 7 && (
        <div class="flex justify-center sm:justify-end mt-8">
          <Link
            to="/gallery"
            class="flex items-center justify-center text-red-600 gap-2"
          >
            <span class="ml-2 font-bold uppercase">View All</span>
            <span class="rounded-full bg-red-600 text-white p-4 h-10 w-10 shadow-lg flex items-center justify-center">
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Explore;
