// import React, { useEffect, useState } from "react";
// import ImageX from "../assets/image/Methodology.jpg"; 
// import ImageXII from "../assets/image/StudentCounsil.jpg";
// import Moto from "../assets/image/toppers.jpg";
// import {firebase} from "../db/Firebase"


// const Toppers = () => {
//   // const [activeTab, setActiveTab] = useState("classX");
//   // const activeImage = activeTab === "classX" ? ImageX : ImageXII; 
//   // const activeLinks = topperLinks[activeTab];
//   const [activeTab, setActiveTab] = useState("classX");
//   const [toppersData, setToppersData] = useState({ classX: [], classXII: [] });

//   useEffect(() => {
//     // Fetch toppers data from Firebase
//     const fetchData = async () => {
//       const dbRef = firebase.database().ref('toppers');
//       dbRef.on('value', (snapshot) => {
//         const data = snapshot.val() || { classX: [], classXII: [] };
//         setToppersData(data);
//       });
//     };

//     fetchData();
//   }, []);

//   // Dynamic data based on active tab
//   const activeData = toppersData[activeTab];
//   const activeImage = activeData.length > 0 ? activeData[0].image : ''; 

//   return (
//     <div className="mt-20 mb-20 font-custom">
//       <img
//         src={Moto}
//         alt="School motto banner"
//         className="w-full h-[200px] md:h-[500px]"
//       />
//       <div className="max-w-screen-lg mx-5 md:mx-20">
//         <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom mt-10">
//           Home &gt; School Topper
//         </div>
//         <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 ">
//           School Topper
//         </h1>
//       </div>

//       <div className="w-4/5  overflow-hidden mx-5 md:mx-20  ">
//         {/* Tabs row */}
//         <div className="flex ">
//           <button
//             className={` text-center   px-5 bg-[#f0f0f0] ${
//               activeTab === "classX"
//                 ? "bg-[#323232] text-white"
//                 : " text-black"
//             }`}
//             onClick={() => setActiveTab("classX")}
//           >
//             Class X
//           </button>
//           <button
//             className={`     text-center py-2 mx-2 px-5 ${
//               activeTab === "classX"
//                 ? " text-black"
//                 : "bg-[#323232] text-white"
//             }`}
//             onClick={() => setActiveTab("classXII")}
//           >
//             Class XII
//           </button>
//         </div>

//         {/* Content row */}
//         <div className="flex flex-col md:flex-row bg-[#f0f0f0]">
//           <div className="flex-1 p-4 ">
//             <h2 className="text-xl font-semibold text-center text-gray-800  border border-black ">{`Topper Links - ${activeTab}`}</h2>
//             <div className=" text-gray-800 ">
//               {activeData.map((item, index) => (
//                 <a
//                   key={index}
//                   href={item.pdf}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className=" text-md text-blue-700 hover:underline border-r border-l border-b border-black items-center flex justify-center"
//                 >
//                   {item.title}
//                 </a>
//               ))}
//             </div>
//           </div>




//           {/* Box for displaying image */}
//           <div className="flex-1 p-4 text-gray-800">
//             <img
//               src={activeImage}
//               alt={`Toppers - ${activeTab}`}
//               className="h-[150px] md:h-[300px] w-auto"
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Toppers;


// import React, { useEffect, useState } from "react";
// import { firebase } from "../db/Firebase";
// import ImageX from "../assets/image/Methodology.jpg"; 
// import ImageXII from "../assets/image/StudentCounsil.jpg";
// import Moto from "../assets/image/toppers.jpg";

// const Toppers = () => {
//   const [activeTab, setActiveTab] = useState("classX");
//   const [toppersData, setToppersData] = useState({ classX: [], classXII: [] });

//   useEffect(() => {
//     const fetchData = async () => {
//       const dbRef = firebase.database().ref('toppers');
//       dbRef.on('value', (snapshot) => {
//         const data = snapshot.val() || { classX: [], classXII: [] };
//         const formattedData = {
//           classX: data.classX || [],
//           classXII: data.classXII || [],
//         };
//         setToppersData(formattedData);
//       });
//     };

//     fetchData();
//   }, []);

//   const activeData = toppersData[activeTab];
//   const defaultImage = activeTab === "classX" ? ImageX : ImageXII;

//   return (
//     <div className="mt-20 mb-20 font-custom">
//       <img src={Moto} alt="School motto banner" className="w-full h-[200px] md:h-[500px]" />
//       <div className="max-w-screen-lg mx-5 md:mx-20">
//         <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom mt-10">
//           Home &gt; School Topper
//         </div>
//         <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5">
//           School Topper
//         </h1>
//       </div>

//       <div className="w-4/5 overflow-hidden mx-5 md:mx-20">
//         <div className="flex">
//           <button
//             className={`text-center px-5 bg-[#f0f0f0] ${activeTab === "classX" ? "bg-[#323232] text-white" : "text-black"}`}
//             onClick={() => setActiveTab("classX")}
//           >
//             Class X
//           </button>
//           <button
//             className={`text-center py-2 mx-2 px-5 ${activeTab === "classX" ? "text-black" : "bg-[#323232] text-white"}`}
//             onClick={() => setActiveTab("classXII")}
//           >
//             Class XII
//           </button>
//         </div>

//         <div className="flex flex-col md:flex-row bg-[#f0f0f0]">
//           <div className="flex-1 p-4">
//             <h2 className="text-xl font-semibold text-center text-gray-800 border border-black">{`Topper Links - ${activeTab}`}</h2>
//             <div className="text-gray-800">
//               {activeData.map((item, index) => (
//                 <a key={index} href={item.pdf} target="_blank" rel="noopener noreferrer" className="block text-md text-blue-700 hover:underline border-r border-l border-b border-black p-2">
//                   {item.title}
//                 </a>
//               ))}
//             </div>
//           </div>

//           <div className="flex-1 p-4 text-gray-800">
//             {/* Use the first image if available, or fallback to the default image */}
//             <img src={activeData.length > 0 && activeData[0].image ? activeData[0].image : defaultImage} alt={`Toppers - ${activeTab}`} className="h-[150px] md:h-[300px] w-auto mx-auto" />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Toppers;

import React, { useEffect, useState } from "react";
import { firebase } from "../db/Firebase";
import Topper from "../assets/image/topper.png";

const Toppers = () => {
  const [activeTab, setActiveTab] = useState("classX"); // Controls which tab is active
  const [entries, setEntries] = useState([]); // Stores all entries from the database

  useEffect(() => {
    const fetchData = async () => {
      const dbRef = firebase.database().ref("toppers");
      dbRef.on("value", (snapshot) => {
        const data = snapshot.val();
        const loadedEntries = [];
        for (let id in data) {
          loadedEntries.push({ id, ...data[id] });
        }
        setEntries(loadedEntries);
      });
    };

    fetchData();
  }, []);

  // Filter entries based on the activeTab
  const filteredEntries = entries.filter(entry => entry.classType === activeTab);
  return (
    <div className="mt-20 mb-20 font-custom">
      <img src={Topper} alt="School motto banner" className="w-full h-[200px] md:h-[500px]" />
      <div className="max-w-screen-lg mx-5 md:mx-20">
        <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom mt-10">
          Home &gt; School Topper
        </div>
        <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5">
          School Topper
        </h1>
      </div>

      <div className="w-4/5 overflow-hidden mx-5 md:mx-20">
        <div className="flex">
          <button
            className={`text-center px-5 bg-[#f0f0f0] ${activeTab === "classX" ? "bg-[#323232] text-white" : "text-black"}`}
            onClick={() => setActiveTab("classX")}
          >
            Class X
          </button>
          <button
            className={`text-center py-2 mx-2 px-5 ${activeTab === "classX" ? "text-black" : "bg-[#323232] text-white"}`}
            onClick={() => setActiveTab("classXII")}
          >
            Class XII
          </button>
        </div>

        <div className="flex flex-col md:flex-row bg-[#f0f0f0]">
          <div className="flex-1 p-4">
            <h2 className="text-xl font-semibold text-center text-gray-800 border border-black">{`Topper Links - ${activeTab === "classX" ? "Class X" : "Class XII"}`}</h2>
            <div className="text-gray-800">
              {filteredEntries.map((item, index) => (
                <a key={index} href={item.pdf} target="_blank" rel="noopener noreferrer" className="block text-md text-blue-700 hover:underline border-r border-l border-b border-black p-2">
                  {item.title}
                </a>
              ))}
            </div>
          </div>

          {/* Assuming the first entry's image as a representative; adjust as necessary */}
          <div className="flex-1 p-4 text-gray-800">
            {filteredEntries.length > 0 && (
              <img src={filteredEntries[0].image || ''} alt={`Toppers - ${activeTab}`} className="h-[150px] md:h-[300px] w-auto mx-auto" />
            )}
          </div>
        </div>
      </div>
    </div>
  
  );
};

export default Toppers;
