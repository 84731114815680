import React from "react";
import CommunityImg from "../../assets/image/community.jpg";

const Community = () => {
  return (
    <div className="mt-16 mb-10">
      <img
        src={CommunityImg}
        alt="School motto banner for secondary"
        className="w-full h-[200px] md:h-[700px] object-cover"
      />
      <div className="bg-white p-8 mx-5 md:mx-10">
        <div className="max-w-screen-lg mx-auto">
          <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom">
            Home &gt; BEYOND ACADEMICS &gt; Community Service
          </div>
          <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
            Community Service
          </h1>
          <p className="text-base font-poppins my-3 font-bold italic">
            "We earn our livelihood through our actions, yet we craft our lives
            through our contributions."
          </p>
          <p className="text-base font-poppins my-3 font-bold italic text-center">
            - Winston Churchill
          </p>
          <p className="text-base font-poppins my-3">
            St. Thomas International School in Varanasi champions the spirit of
            giving and community service, hosting an engaged community service
            club that organizes a broad spectrum of activities. These
            initiatives are designed to foster empathy and compassion towards
            those in less fortunate circumstances, promote environmental
            consciousness and respect for all kinds of work. Our students are
            motivated to engage in a variety of social service activities
            throughout the year. These include engaging with rural communities,
            assisting individuals with mental and physical disabilities, working
            alongside laborers, developing compost pits, maintaining the campus
            garden, planting trees, recycling paper and managing waste amongst
            others.
          </p>
          <p className="text-base font-poppins my-3">
            Our community service efforts extend to organizing visits to old age
            homes, special schools and informing the local community about
            effective waste management practices, maintaining campus
            cleanliness and undertaking projects that benefit the larger
            community. The school embraces the 'joy of giving' through organized
            visits to old age homes, special schools, orphanages and similar
            institutions, reinforcing our commitment to making a positive impact
            on society and the environment.
          </p>
         
        </div>
      </div>
    </div>
  );
};

export default Community;
