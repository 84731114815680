import firebase from "firebase/compat/app";
import "firebase/compat/database"; 
import 'firebase/compat/storage'; 
import "firebase/compat/auth";
import {getStorage} from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyCPeVgJlEtN32HR9ckErpPGD4aP1Hu5VoI",
  authDomain: "st-thomas-e104b.firebaseapp.com",
  databaseURL: "https://st-thomas-e104b-default-rtdb.firebaseio.com",
  projectId: "st-thomas-e104b",
  storageBucket: "st-thomas-e104b.appspot.com",
  messagingSenderId: "555217441037",
  appId: "1:555217441037:web:d01540d985708318b7e044",
  measurementId: "G-M4BYJJRN5J"
};

const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth(); 
const storage = firebase.storage();

export { firebase, auth, storage, getStorage };;