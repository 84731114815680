import React from "react";
import PrincipalImg from "../../assets/image/principle.jpeg";

const Principal = () => {
  return (
    <div className="mt-20 mb-20">
      <div className="w-full h-32 bg-[#0c4680]"></div>
      <div className="max-w-screen-lg mx-5 md:mx-20">
        <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom mt-10">
          Home &gt; About Us &gt; Principal
        </div>
        <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
          Principal
        </h1>
        <div className="flex justify-center items-center py-5">
          <div className="border-2 border-gray-200 shadow-lg p-1">
            <img
              src={PrincipalImg}
              alt="Vision and Mission"
              className=" h-80 w-[450px]"
            />
          </div>
        </div>
        <h1 className="text-4xl font-bold font-custom py-5 mb-2">
          Smt.Manisha Verma
        </h1>
        <p className="text-base font-poppins py-5">
          Dear Parents and well-wishers,
        </p>
        <p className="text-base font-poppins ">
          Thank you for choosing St. Thomas International School for your child.
          Our school is commited to give its pupils a solid foundation in a
          nurturing environment, one that will allow their natural gifts to
          unfold and make learning a pleasurable experience . It will be our
          privilege to make your child’s transition to our smooth and enjoyable
          and we look forward to enriching your child’s learning years with fun
          and interactive education",
        </p>

        <p className="text-base font-poppins py-3">
          We have created an environment to enable children of all ages to
          fulfill their potential while developing into well mannered, well
          groomed, independent individuals with high self esteem. Our School is
          committed to provide an education experience that is the richest, most
          diverse and most exciting in an atmosphere that provides support,
          encouragement and care for every child. Our curriculum is broad and
          balanced and provides for the academic,moral,physical,creative ,
          personal and social development of every child.
        </p>
        <p className="text-base font-poppins py-3">
          Our School follows an open-door policy and we like to interact
          regularly with parents during Parent-Teacher meeting I would like to
          personally meet if you have any queries regarding the development of
          your child.
        </p>
        <p className="text-base font-poppins py-3">
          At St. Thomas International School it is our aim not only to pursue
          academic excellence but also to motivate our students to be life long
          learners, critical thinkers and productive members of an
          ever-changing global society. We try to provide our students with an
          atmosphere for multifaceted development where children are encouraged
          to channelize their potential in the pursuit of excellence. We aim to
          nurture the talents, skills and abilities of each student and provide
          them with a plateform to think, express and exhibit their skills so as
          to empower them to negotiate several issues that confront them, with
          the teacher being a facilitator. Our approach to learning is
          childcentric, encouraging acvity-based learning and inculcating a
          scientific temper and a spirit of free enquiry. In our curriculum, the
          student is the primary focus, and each child is involved in the
          learning process. The purpose of the education received by our children
          is to replace an empty mind with an open one. We motivate our teachers
          to provide an atmosphere for discovery, where students are encouraged
          to be creative and curious instead of only loading the children with an
          academic burden. We try to constantly improve our teaching methodology
          so that it turns learning into a combination of classroom
          study,research, and scientific discovery."
        </p>
        <p className="text-base font-poppins py-3 ">
          While academic excellence is our major thrust, the school is also
          devoted to prepare our students for life,prepare them to face the
          challenges of tomorrow, and encourage them to be socially relevant. We
          encourage each child to develop in their special field to interest so
          as to best showcase their particular type of talent and grow to their
          full potential, and constantly groom them to pass out as men and women
          competent to bear responsibility in all walks of life."
        </p>
      </div>
    </div>
  );
};

export default Principal;
