import React from "react";
import Facility from "../../assets/image/facilities.jpg";

const Facilities = () => {
  return (
    <div className="mt-16 mb-10">
      <img
        src={Facility}
        alt="School motto banner for secondary"
        className="w-full h-[200px] md:h-[500px]"
      />
      <div className="bg-white p-8 mx-5 md:mx-10">
        <div className="max-w-screen-lg mx-auto">
          <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom">
            Home &gt; Infrastructure &gt; Facilities
          </div>
          <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
            Facilities
          </h1>
          <p className="text-base font-poppins my-3">
            Situated across a sprawling vast landscape, our school is
            enveloped in lush green lawns, complemented by meticulously designed
            buildings that provide a secure and child-friendly atmosphere
          </p>
          <ul className="list-disc font-poppins">
            <li className="ml-10 py-1">
              Our infrastructure comprises well-ventilated classrooms, modern
              science laboratories, and extensive libraries, facilitating an
              enriching learning experience for our students.
            </li>
            <li className="ml-10 py-1">
              The campus features state-of-the-art amenities such as a
              cutting-edge dance studio, AV room, conference facility and an
              art gallery, providing platforms for students to exhibit their
              creative prowess.
            </li>
            <li className="ml-10 py-1">
              Tailored to accommodate pre-primary, primary, and senior wings,
              our campus offers spacious indoor and outdoor areas for various
              educational and recreational activities.
            </li>
            <li className="ml-10 py-1">
              To ensure uninterrupted operations, silent generators are
              strategically installed, guaranteeing a consistent and effective
              power supply throughout the premises.{" "}
            </li>
            <li className="ml-10 py-1">
              We pride ourselves on our top-of-the-line indoor and outdoor
              sports facilities, fostering physical fitness and a spirit of
              healthy competition among our students.{" "}
            </li>
            <li className="ml-10 py-1">
              Security remains paramount with our CCTV-monitored campus and the
              integration of fire alarms and firefighting systems, further
              enhancing safety protocols.{" "}
            </li>
          </ul>
          <h1 className="text-2xl font-bold font-custom py-4 ">
            Classrooms and Facilities
          </h1>
          <p className="text-base font-poppins my-3">
            Our infrastructure features well-ventilated classrooms, advanced
            science labs, and resourceful libraries conducive to learning.
            Additionally, we offer specialized spaces including a dance studio,
            AV room, conference room, and art gallery.
          </p>
          <h1 className="text-2xl font-bold font-custom py-4 ">
            Campus Amenities
          </h1>
          <p className="text-base font-poppins my-3">
            With designated areas for pre-primary, primary, and senior wings,
            our campus provides ample room for both indoor and outdoor
            activities. It is equipped with silent generators, state-of-the-art
            sports facilities, and CCTV surveillance for security including
            fire alarms and firefighting systems.
          </p>
          <h1 className="text-2xl font-bold font-custom py-4 ">Library</h1>
          <p className="text-base font-poppins my-3">
            Our extensive library houses many titles catering to students
            and staff alike. It features encyclopedias, dictionaries,
            literature, career resources and periodicals fostering a conducive
            environment for exploration and learning.
          </p>
          <h1 className="text-2xl font-bold font-custom py-4 ">Art & Craft</h1>
          <p className="text-base font-poppins my-3">
            Inspiring creativity, our well-planned art rooms offer diverse
            facilities for sketching, painting and artistic expression across
            different age groups.
          </p>
          <h1 className="text-2xl font-bold font-custom py-4 ">Meditation Hall</h1>
          <p className="text-base font-poppins my-3">
            Our meditation hall serves as a devine sanctuary fostering personal
            growth, worship and  support through prayer and sprituality.
          </p>
          <h1 className="text-2xl font-bold font-custom py-4 ">Dispensary</h1>
          <p className="text-base font-poppins my-3">
            Equipped with necessary medical facilities and staffed by trained
            people our dispensary ensures the health and well-being of our
            students through check-ups and emergency care.
          </p>
          <h1 className="text-2xl font-bold font-custom py-4 ">
            Music and Band
          </h1>
          <p className="text-base font-poppins my-3">
            With gifted instructors and a variety of instruments our music
            department nurtures talent and cultural appreciation promoting
            participation in inter-school competitions
          </p>
          <h1 className="text-2xl font-bold font-custom py-4 ">Dance Room</h1>
          <p className="text-base font-poppins my-3">
            Featuring dedicated spaces for various dance forms our studios are
            equipped with essential amenities and AV equipment, encouraging
            students to explore Western and Indian dance tradition
          </p>
          <h1 className="text-2xl font-bold font-custom py-4 ">
            Safety Measures
          </h1>
          <p className="text-base font-poppins my-3">
            Emphasizing safety as a priority we implement strategic risk
            management practices, conduct disaster preparedness drills, and
            maintain a comprehensive CCTV monitoring system.
          </p>
          <h1 className="text-2xl font-bold font-custom py-4 ">
            Transport 
          </h1>
          <p className="text-base font-poppins my-3">
            Our fleet of buses ensures safe transportation with GPS facilities for students. 
          </p>
        </div>
      </div>
    </div>
  );
};

export default Facilities;
