import React from "react";
import FirstNav from "./FirstNav";
import SecondNav from "./SecondNav";
import ThirdNav from "./ThirdNav";


const Navbar = () => {
  return (
    <div className="relative font-bold font-custom">
      <FirstNav />
      <SecondNav />
      <ThirdNav />
    </div>
  );
};

export default Navbar;
