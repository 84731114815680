import React from "react";
import AssessmentImg from "../../assets/image/preprimary.jpg";

const Assessment = () => {
  return (
    <div className="mt-16 mb-10">
      <img
        src={AssessmentImg}
        alt="School motto banner"
        className="w-full h-[200px] md:h-[500px] object-cover"
      />
      <div className="bg-white p-8 mx-5 md:mx-10">
        <div className="max-w-screen-lg mx-auto">
          <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom">
            Home &gt; Academics &gt;ASSESSMENT &gt; PRE-PRIMARY
          </div>
          <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
            Pre-Primary
          </h1>
          <p className="text-base font-poppins my-3">
            Pre- primary education provides a stimulating environment for an all
            round development of the child which lays the foundation for a more
            formal approach to learning.{" "}
          </p>
          <p className="text-base font-poppins my-3">
            Learning in the pre-primary years is done through a thematic
            approach wherein each child’s unique interests, ability, personality
            and style of learning is recognized and can be evaluated. Children
            at St. Thomas International School learn best when they are actively involved in the
            learning process through a high degree of participation,
            contribution and production.{" "}
          </p>
          <p className="text-base font-poppins my-3">
            Evaluation happens through a continuous and interactive process
            involving group activities, presentations, worksheets, speaking and
            comprehension skills.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Assessment;
