import React from "react";
import Moto from "../../assets/image/curriculam.jpg";

const Curriculam = () => {
  return (
    <div className="mt-16 mb-10">
      <img src={Moto} alt="School motto banner" className="w-full h-[200px] md:h-[500px]" />
      <div className="bg-white p-8 mx-5 md:mx-10">
        <div className="max-w-screen-lg mx-auto">
          <div className="text-sm uppercase tracking-wide text-gray-400 mb-2 font-bold font-custom">
            Home &gt; Academics &gt; Curriculam
          </div>
          <h1 className="text-5xl font-bold text-[#0c4680] font-custom py-5 mb-5">
          Curriculam
          </h1>
          <p className="text-base font-poppins my-3">
          The desire to know is far more important than achievement performance measures. It is the supreme art of the teacher to awaken joy in creative expression of knowledge. Children learn to converse even before they learn to speak. We, as elders, play a vital role in making their self-talk positive and realistic by cultivating their natural confidence, courage and sense of well-being through words of affirmation which has a therapeutic and transformational effect on their holistic growth and development. We at St. Thomas’, truly believe in it and strive to awaken that desire in your child, making them socially responsible and focused; ready to leave an indelible mark on the society.          </p>
          <p className="text-base font-poppins my-3">
          The school follows curriculum prescribed by CBSE blended with an array of activities, practicals, trainings to name a few.          </p>
        </div>
      </div>
    </div>
  );
};

export default Curriculam;
